<template>
  <crud-form
    :titulo-pagina="tituloPagina"
    :is-editting="isEditting"
    :mensagem-pergunta-remocao="mensagemPerguntaRemocao"
    :mostra-botao-salvar-continuar="false"
    @deleteEvent="remover"
    @closeDialogEvent="closeDialog"
    @saveEvent="saveEventHandler"
    @save-continue-event="saveContinueEvent"
  >
    <v-container class="py-0">
      <v-row>
        <v-col :cols="isEditting ? 10 : 12">
          <v-text-field
            v-model="objEditar.nome"
            :rules="[required]"
            :label="$t('EmpresaClientesForm.nome')"
            required
            prepend-icon="mdi-account-edit"
          />
        </v-col>

        <v-col cols="2" v-if="isEditting">
          <div v-show="objEditar.idImagemLogo">
            <v-btn
              @click="editImagemLogo()"
              class="hidden-sm-and-down"
              color="success"
              >{{ $t("EmpresaClientesForm.editarLogo") }}</v-btn
            >
          </div>
          <div v-show="!objEditar.idImagemLogo">
            <v-btn
              @click="editImagemLogo()"
              class="hidden-sm-and-down"
              color="success"
              >{{ $t("EmpresaClientesForm.enviarLogo") }}</v-btn
            >
          </div>
        </v-col>

        <v-col cols="5" class="hidden-md-and-up text-left">
          <span>{{ $t("EmpresaClientesForm.tipoDePessoa") }}</span>
        </v-col>
        <v-col cols="7" class="hidden-md-and-up text-right">
          <v-radio-group v-model="objEditar.tipoPessoa" :mandatory="true" row>
            <v-radio :label="$t('EmpresaClientesForm.fisica')" value="F" />
            <v-radio :label="$t('EmpresaClientesForm.juridica')" value="J" />
          </v-radio-group>
        </v-col>
        <v-col cols="12" class="hidden-sm-and-down">
          <span>{{ $t("EmpresaClientesForm.tipoDePessoa") }}</span>
          <v-radio-group v-model="objEditar.tipoPessoa" :mandatory="true" row>
            <v-radio :label="$t('EmpresaClientesForm.fisica')" value="F" />
            <v-radio :label="$t('EmpresaClientesForm.juridica')" value="J" />
          </v-radio-group>
        </v-col>

        <v-col cols="12" sm="4">
          <v-text-field
            v-model="objEditar.cpfOuCnpj"
            :label="
              objEditar.tipoPessoa === 'F'
                ? $t('EmpresaClientesForm.cpf')
                : $t('EmpresaClientesForm.cnpj')
            "
            prepend-icon="mdi-account-card-details"
          />
        </v-col>

        <v-col v-if="objEditar.tipoPessoa === 'J'" cols="12" sm="8">
          <v-text-field
            v-model="objEditar.razaoSocial"
            :label="$t('EmpresaClientesForm.razaoSocial')"
            prepend-icon="mdi-factory"
          />
        </v-col>

        <v-col v-if="objEditar.tipoPessoa === 'F'" cols="12" sm="5">
          <v-text-field
            v-model="objEditar.rg"
            :label="$t('EmpresaClientesForm.rg')"
            prepend-icon="mdi-account-card-details"
          />
        </v-col>
        <v-col v-if="objEditar.tipoPessoa === 'F'" cols="12" sm="3">
          <v-text-field
            v-model="objEditar.orgaoEmissor"
            :label="$t('EmpresaClientesForm.orgaoEmissor')"
            prepend-icon="mdi-account-card-details"
          />
        </v-col>
        <v-col v-if="objEditar.tipoPessoa === 'F'" cols="12" sm="2">
          <v-text-field
            v-model="objEditar.dataRG"
            :label="$t('EmpresaClientesForm.dataRG')"
            prepend-icon="mdi-account-card-details"
          />
        </v-col>
        <v-col v-if="objEditar.tipoPessoa === 'F'" cols="12" sm="5">
          <v-text-field
            v-model="objEditar.nomePai"
            :label="$t('EmpresaClientesForm.nomePai')"
            prepend-icon="mdi-account-edit"
          />
        </v-col>
        <v-col v-if="objEditar.tipoPessoa === 'F'" cols="12" sm="5">
          <v-text-field
            v-model="objEditar.nomeMae"
            :label="$t('EmpresaClientesForm.nomeMae')"
            prepend-icon="mdi-account-edit"
          />
        </v-col>
        <v-col cols="12" sm="5">
          <v-text-field
            v-model="objEditar.telefone1"
            :label="$t('EmpresaClientesForm.telefone1')"
            prepend-icon="mdi-phone-classic"
            type="number"
          />
        </v-col>
        <v-col cols="12" sm="5">
          <v-text-field
            v-model="objEditar.tipoTelefone1"
            :label="$t('EmpresaClientesForm.tipoTelefone1')"
          />
        </v-col>
        <v-col cols="12" sm="5">
          <v-text-field
            v-model="objEditar.telefone2"
            :label="$t('EmpresaClientesForm.telefone2')"
            prepend-icon="mdi-phone-classic"
            type="number"
          />
        </v-col>
        <v-col cols="12" sm="5">
          <v-text-field
            v-model="objEditar.tipoTelefone2"
            :label="$t('EmpresaClientesForm.tipoTelefone2')"
          />
        </v-col>
        <v-col cols="12" sm="5">
          <v-text-field
            v-model="objEditar.telefone3"
            :label="$t('EmpresaClientesForm.telefone3')"
            prepend-icon="mdi-phone-classic"
            type="number"
          />
        </v-col>
        <v-col cols="12" sm="5">
          <v-text-field
            v-model="objEditar.tipoTelefone3"
            :label="$t('EmpresaClientesForm.tipoTelefone3')"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="objEditar.email1"
            :label="$t('EmpresaClientesForm.email1')"
            prepend-icon="mdi-email-outline"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="objEditar.email2"
            :label="$t('EmpresaClientesForm.email2')"
            prepend-icon="mdi-email-outline"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="objEditar.email3"
            :label="$t('EmpresaClientesForm.email3')"
            prepend-icon="mdi-email-outline"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-switch
            v-model="objEditar.habilitado"
            :label="$t('EmpresaClientesForm.habilitado')"
            class="purple-input"
            prepend-icon="mdi-checkbox-marked-circle-outline"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-menu
            ref="menuDataExpiracao"
            v-model="menuDataExpiracao"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="dataExpiracaoFormatted"
                :label="$t('EmpresaClientesForm.dataExpiracao')"
                persistent-hint
                readonly
                :rules="[required]"
                required
                @blur="
                  objEditar.dataExpiracao = parseDate(dataExpiracaoFormatted)
                "
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="objEditar.dataExpiracao"
              no-title
              dark
              prepend-icon="mdi-calendar-text"
              @input="menuDataExpiracao = false"
            />
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6">
          <v-select
            v-model="objEditar.acaoSemEstoque"
            :label="$t('EmpresaClientesForm.acaoSemEstoque')"
            item-value="value"
            item-text="text"
            :items="acoesSemEstoque"
            prepend-icon="mdi-calendar-alert"
          />
        </v-col>
        <v-alert
          v-model="alertValidacao"
          dismissible
          type="error"
          transition="scale-transition"
        >
          {{ error }}
        </v-alert>
      </v-row>
    </v-container>
    <imagem-upload
      :is-editting="isEditingLogo"
      :id-imagem="objEditar.idImagemLogo"
      :dialog.sync="showDialogLogo"
      @imagem-salva="saveLogo"
      @imagem-removida="removeLogo"
    />
  </crud-form>
</template>

<script>
import Servico from "@/services/EmpresaClientesService";
import DateUtils from "../utils/DateUtils";
import Vue from "vue";

export default {
  data: () => ({
    objPadrao: {
      nome: "",
      tipoPessoa: "F",
      cpfOuCnpj: "",
      rg: "",
      razaoSocial: "",
      orgaoEmissor: "",
      dataRG: null,
      nomePai: "",
      nomeMae: "",
      profissao: "",
      telefone1: null,
      tipoTelefone1: "",
      telefone2: null,
      tipoTelefone2: "",
      telefone3: null,
      tipoTelefone3: "",
      email1: "",
      email2: "",
      email3: "",
      habilitado: true,
      dataExpiracao: null,
      idImagemLogo: 0,
      id: 0
    },
    objEditar: {
      nome: "",
      tipoPessoa: "F",
      cpfOuCnpj: "",
      rg: "",
      razaoSocial: "",
      orgaoEmissor: "",
      dataRG: null,
      nomePai: "",
      nomeMae: "",
      profissao: "",
      telefone1: null,
      tipoTelefone1: "",
      telefone2: null,
      tipoTelefone2: "",
      telefone3: null,
      tipoTelefone3: "",
      email1: "",
      email2: "",
      email3: "",
      habilitado: true,
      dataExpiracao: null,
      idImagemLogo: 0,
      id: 0
    },
    menuDataExpiracao: false,
    required: value => !!value || "Campo Obrigatório",
    isEditting: false,
    error: null,
    validForm: false,
    alertValidacao: false,

    showDialogLogo: false,
    isEditingLogo: false
  }),
  computed: {
    dataExpiracaoFormatted: function() {
      return DateUtils.formatDate(this.objEditar.dataExpiracao);
    },
    mensagemPerguntaRemocao() {
      return this.$i18n.t("padrao_deseja_remover_registro");
    },
    tituloPagina() {
      return this.$i18n.t("EmpresaClientesForm.tituloPagina");
    },
    acoesSemEstoque() {
      return [
        {
          text: this.$t("EmpresaClientesForm.acaoSemEstoque_N"),
          value: "N"
        },
        {
          text: this.$t("EmpresaClientesForm.acaoSemEstoque_A"),
          value: "A"
        },
        {
          text: this.$t("EmpresaClientesForm.acaoSemEstoque_B"),
          value: "B"
        }
      ];
    }
  },
  created() {
    this.$root.$emit("alteraMostraPesquisa", false);

    let id = this.$route.params.id;
    if (id) {
      this.editar(id);
    } else {
      this.isEditting = false;
      this.objEditar = this.objPadrao;
    }
  },
  methods: {
    parseDate(date) {
      return DateUtils.parseDate(date);
    },
    remover() {
      const registro = this.objEditar;
      try {
        Servico.delete(registro).then(response => {
          this.$root.$emit("mostra_dialogo_info", {
            dialogoTitulo: this.$t("padrao_sucesso"),
            dialogoDescricao: this.$t("padrao_registro_removido_sucesso")
          });
          this.buscar();
        });
      } catch (error) {
        this.$root.$emit("mostra_dialogo_erro", {
          dialogoDescricao:
            this.$i18n.t("padrao_ocorreu_um_erro_ultima_operacao") +
            "\n" +
            error
        });
      }
    },
    saveContinueEvent() {
      this.inserir(this.objEditar, true);
    },
    closeDialog() {
      this.buscar();
    },
    saveEventHandler() {
      if (this.isEditting) {
        this.atualizar(this.objEditar);
      } else {
        this.inserir(this.objEditar);
      }
    },
    atualizar(registro, irParaListagem) {
      try {
        Servico.update(registro).then(response => {
          if (!response) {
            this.$root.$emit("mostra_dialogo_erro", {
              dialogoDescricao: this.$i18n.t(
                "padrao_ocorreu_um_erro_ultima_operacao"
              )
            });
          } else if (response.error) {
            this.$root.$emit("mostra_dialogo_erro", {
              dialogoDescricao:
                this.$i18n.t("padrao_ocorreu_um_erro_ultima_operacao") +
                "\n" +
                response.error
            });
          } else {
            this.$root.$emit("mostra_dialogo_info", {
              dialogoTitulo: "Sucesso",
              dialogoDescricao: this.$t("padrao_registro_salvo_sucesso")
            });
            if (irParaListagem) {
              this.buscar();
            }
          }
        });
      } catch (error) {
        this.$root.$emit("mostra_dialogo_erro", {
          dialogoDescricao:
            this.$i18n.t("padrao_ocorreu_um_erro_ultima_operacao") +
            "\n" +
            error
        });
      }
    },
    buscar() {
      this.$router.push("/empresaClientes");
    },
    inserir(registro, continuar) {
      try {
        Servico.insert(registro)
          .then(response => {
            if (!response) {
              this.$root.$emit("mostra_dialogo_erro", {
                dialogoDescricao:
                  this.$i18n.t("padrao_ocorreu_um_erro_ultima_operacao") +
                  ": null"
              });
            } else if (response.error) {
              this.$root.$emit("mostra_dialogo_erro", {
                dialogoDescricao: "" + response.error
              });
            } else {
              this.$root.$emit("mostra_dialogo_info", {
                dialogoTitulo: this.$t("padrao_sucesso"),
                dialogoDescricao: this.$t("padrao_registro_salvo_sucesso")
              });
              if (continuar) {
                this.editar(response.data.id);
              } else {
                this.buscar();
              }
            }
          })
          .catch(error => {
            this.$root.$emit("mostra_dialogo_erro", {
              dialogoDescricao:
                this.$i18n.t("padrao_ocorreu_um_erro_ultima_operacao") +
                "\n" +
                error
            });
          });
      } catch (error) {
        this.$root.$emit("mostra_dialogo_erro", {
          dialogoDescricao:
            this.$i18n.t("padrao_ocorreu_um_erro_ultima_operacao") +
            "\n" +
            error
        });
      }
    },
    editar(id) {
      Servico.findById(id)
        .then(registro => {
          if (registro.id) {
            this.objEditar = registro;
            this.isEditting = true;
          } else if (registro.data.id) {
            this.objEditar = registro.data;
            this.isEditting = true;
          } else {
            this.isEditting = false;
          }
        })
        .catch(() => {
          this.isEditting = false;
        });
    },
    editImagemLogo() {
      this.isEditingLogo = !!this.objEditar.idImagemLogo;
      this.showDialogLogo = true;
    },
    saveLogo(idImagem) {
      console.log(idImagem);
      this.setLogoAndSave(idImagem);
    },
    removeLogo() {
      this.setLogoAndSave(null);
    },
    setLogoAndSave(idImagem) {
      this.objEditar.idImagemLogo = idImagem;
      if (this.objEditar.id) {
        this.atualizar(this.objEditar, false);
        this.showDialogLogo = false;
      } else {
        this.inserir(this.objEditar, true);
        this.showDialogLogo = false;
      }
    }
  }
};
</script>
